import { Link } from "gatsby"
import React, { useState, useEffect, useContext } from "react"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"

const TechnicalPerspective = () => {
  const [isMobile, setIsMobile] = useState(false)
  const checkScreenSize = () => {
    if (window.innerWidth <= 600) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    checkScreenSize()
    window.addEventListener("resize", checkScreenSize,   {passive:true})
  }, [])
  return (
    <Layout>

      <div
        className="techical_perspective-section"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div style={{ maxWidth: "1024px", padding: "0 20px" }}>
          <h1
            style={{
              fontSize: "24px",
              fontWeight: "500",
              textAlign: "center",
              margin: "0 auto 20px",
            }}
          >
            Data Security
          </h1>
          <div class="page-body">
            <p>
              Cubo&nbsp;employs advanced technology to secure and back up your
              account information on protected and guarded systems that allow
              for scalability, performance and reliability.
            </p>
            <p>
              <h3 style={{ fontSize: isMobile ? "18px" : "" }}>
                Your Data is Yours
              </h3>
            </p>
            <p>
              We do not share or sell the organizational or personal information
              for you or any team member connected to your account. Period. Your
              team's data belongs to you and your team. You may export it at
              anytime. We will purge your data from our data stores and logs at
              your request or automatically upon account cancellation. We cannot
              access your data unless you ask us to for troubleshooting
              purposes.
            </p>
            <p>
              <h3 style={{ fontSize: isMobile ? "18px" : "" }}>
                Your Data is Protected and Secure
              </h3>
            </p>
            <p>
              Whenever your data is in transit between you and us, everything is
              encrypted, and sent using HTTPS. Each user in your organization is
              provided with a unique user name and h3 password or expiring
              authentication token that must be entered each time a user signs
              on. Cubo&nbsp;issues a session cookie only to record encrypted
              authentication information for the duration of a specific session,
              not to store usernames or passwords. Our session cookies use the
              secure and HTTP only flags. Your credit card data is securely
              submitted directly from your browser to a leading, PCI Service
              Provider Level 1 payment gateway. It never touches our servers. We
              keep rotating, daily backups of all account data.
            </p>
            <p>
              Under the hood, all data is encrypted in transit and at rest.
              Sensitive data, like passwords, authentication tokens and status
              check-ins are never logged. Your data is housed in physically
              secured, SOC2 compliant, and ISO 27001/27017/27018 certified data
              centers within the United States.
            </p>
            <p>
              <h3 style={{ fontSize: isMobile ? "18px" : "" }}>
                Our Systems are Monitored 24x7
              </h3>
            </p>
            <p>
              Our managed hosting provider operations team monitors all hosts
              and services for integrity and availability, 24 hours a day, 7
              days a week. Our software infrastructure is updated regularly with
              the latest security patches. Both manual and automated
              vulnerability scans and security reviews are continuously
              performed.
            </p>
            <p>
              <h3 style={{ fontSize: isMobile ? "18px" : "" }}>
                More Information
              </h3>
            </p>
            {/* <Link to="/contact-us">&nbsp;Contact us&nbsp;</Link> */}
            Contact us anytime for additional details.
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TechnicalPerspective
